
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconHouse, BIconWatch, BIconWallet2, BIconBuilding, BIconThreeDots } from "bootstrap-vue";
import { GenreCollection } from "@/types/Filters";

@Component({
  components: {
    BIcon,
    BIconHouse,
    BIconWatch,
    BIconWallet2,
    BIconBuilding,
    BIconThreeDots,
  },
})
export default class CardFooterData extends Vue {
  @Prop({ default: true }) isAsta!: boolean;
  @Prop({ default: false }) showIcon!: boolean;
  @Prop() item!: any;
  @Prop() iscard!: boolean;
  @Prop({ default: false }) clickdisabled: boolean;
  @Prop({ default: false }) hasGracePeriod: boolean;

  public getGenre() {
    return new GenreCollection().findGenreFromAlias(this.item?.genre);
  }
}
