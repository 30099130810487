export type Subject = {
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  verified?: string | boolean;
};

export type LegalNotice = {
  identifier?: string;
  version?: string;
};

export type Preferences = {
  legal_documents?: string;
};

export const saveConsent = (
  formId: string,
  btnSubmitId: string,
  subject: Subject,
  preferences: Preferences,
  legal_notices: LegalNotice[],
  exclude?: string[]
) => {
  {
    // @ts-ignore
    _iub.cons_instructions.push([
      "submit",
      {
        submitElement: document.getElementById(btnSubmitId),
        form: {
          selector: document.getElementById(formId),
          map: {
            subject,
            preferences,
            exclude,
          },
        },
        consent: {
          legal_notices,
        },
      },
      {
        error: function (response: any) {
          console.error(response);
        },
        success: function (response: any) {
          console.log(response);
        },
      },
    ]);
  }
};
