import { Component } from "nuxt-property-decorator";
import ResponseHandler from "~/helpers/validations/ResponseHandler";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  integer,
  sameAs,
  minLength,
  maxLength,
  requiredIf, //@ts-ignore
} from "vuelidate/lib/validators";

@Component({})
export default class SegnalazioniValidator extends ResponseHandler {
  // definisco le regole di validazione
  @Validations()
  validations = {
    form: {
      nome: {
        requiredIfPrivato: requiredIf(function () {
          // @ts-ignore
          return this.$v.form.tipo_persona.$model === "Privato";
        }),
      },
      cognome: {
        requiredIfPrivato: requiredIf(function () {
          // @ts-ignore
          return this.$v.form.tipo_persona.$model === "Privato";
        }),
      },
      ragione_sociale: {
        requiredIfAzienda: requiredIf(function () {
          // @ts-ignore
          return this.$v.form.tipo_persona.$model === "Persona giuridica";
        }),
      },
      email: { required, email },
      telefono: { required, integer },
      IVG_competenza: { required },
      numero_procedura: { required },
      anno_procedura: { required, integer, minLength: minLength(4), maxLength: maxLength(4) },
      segnalazione: { required, minLength: minLength(10) },
      tipo_persona: { required },
      data_acquisto: {},
      privacy: {
        sameAs: sameAs(() => true),
      },
    },
  };
}
