
import { Component, Getter } from "nuxt-property-decorator";
import axios from "axios";
import SegnalazioniValidator from "@/helpers/validations/segnalazioni";
import FormSegnalazioni from "~/types/forms/FormSegnalazioni";
import { DropdownIvg, ResponseDropdownIvg } from "~/types/responses/chisiamo/ResponseChiSiamoInterface";
import { saveConsent } from "@/types/forms/saveConsent";

@Component({})
export default class SegnalazioniForm extends SegnalazioniValidator {
  @Getter("tenant/privacy") privacy!: any;
  @Getter("tenant/currentToken") token: string;
  public form: FormSegnalazioni = new FormSegnalazioni();
  public loading: boolean = false;
  public options: Array<any> = [
    { text: "Persona giuridica", value: "Persona giuridica" },
    { text: "Privato", value: "Privato" },
  ];
  public optionsIvg: Array<any> = [{ text: "Seleziona IVG", value: null }];

  fetchOnServer() {
    return false;
  }

  async fetch() {
    try {
      const { data } = await axios.get<ResponseDropdownIvg>(this.$config.astaEndpoint + "IVG/dropdown-list", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });
      this.optionsIvg = [
        ...this.optionsIvg,
        ...data.data.map((item: DropdownIvg) => {
          return {
            text: item.description,
            value: item.id,
          };
        }),
      ];
    } catch (e) {
      this.$errorHandler(e);
    }
  }

  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      // console.error(e);
    }
  }

  public async onSubmit() {
    this.$v.form.$touch();

    if (this.$v.$invalid) {
      return;
    }
    this.loading = true;

    const data = this.form;

    try {
      const token = await this.$recaptcha.execute("segnalazione");
      data.recaptcha = token;
    } catch (error) {
      throw error;
    }

    try {
      await axios.post(this.$config.astaEndpoint + "segnalazione", data, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });
      this.success = true;

      saveConsent(
        "segnalazione-form",
        "segnalazione-submit",
        { first_name: "nome", last_name: "cognome", email: "email" },
        { legal_documents: "privacy" },
        [{ identifier: "privacy_policy", version: "15/05/2018" }]
      );

      this.resetForm();
    } catch (error: any) {
      console.error(error);
    }
  }

  public resetForm() {
    this.form = new FormSegnalazioni();
    setTimeout(() => {
      this.$v.$reset();
      this.loading = false;
    }, 10);
  }
}
