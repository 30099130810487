import { Vue, Component } from "nuxt-property-decorator";

// @ts-ignore
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";

Vue.component("FormError", templates.singleErrorExtractor.foundation6);
Vue.use(vuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.foundation6,
  messages: {
    required: "Obbligatorio",
    requiredIf: "Il codice fiscale o la partita iva sono obbligatori",
    requiredIfPrivato: "Obbligatorio per Privato",
    requiredIfAzienda: "Obbligatorio per le Aziende",
    email: "Formato email non valido",
    sameAs: "Deve essere accettato",
    minLength: "Minimo {min} caratteri",
    maxLength: "Massimo {max} caratteri",
    integer: "Deve essere composto da soli numeri",
  },
});

@Component
export default class Extractors extends Vue {}
