import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { $axios } from "~/utils/api";
import Platform from "@/types/domains/platform";
import { HeaderData, FooterData } from "@/types/components";
import { startCase } from "@/helpers/startCase";
import { store } from "./index";
import { ClientMap, CookieData } from "~/types/responses/inserzione/ResponseInserzioneInterface";
import { ServerResponse } from "@/types/responses/Response";
import { IvgIdentity } from "@/types/components";
import axios from "axios";

@Module({
  name: "tenant",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class TenantStore extends VuexModule {
  public token: string = "";
  public domain: string = "";
  public layoutData: any = {};
  public seoData: any = {};
  public main: boolean = true;
  public availableGenre: string[] = [];
  public mainMenu: any[] = [];

  @Mutation
  setToken(str: string) {
    this.token = str;
  }

  @Mutation
  setDomain(str: string) {
    this.domain = str;
  }

  @Mutation
  setIsMain(bool: boolean) {
    this.main = bool;
  }

  @Mutation
  setLayoutData(data: any) {
    this.layoutData = { ...data };
  }

  @Mutation
  setSeoData(data: any) {
    this.seoData = { ...data };
  }

  @Mutation
  setAvailableGenre(data: any) {
    this.availableGenre = [...data];
  }

  //ACTIONS
  @Action({ commit: "setToken" })
  getToken(domain: string) {
    return new Platform(domain).getToken();
  }

  @Action({ commit: "setDomain" })
  getDomain(domain: string) {
    return domain;
  }

  @Action({ commit: "setIsMain" })
  getMain(domain: string) {
    return new Platform(domain).isAsta;
  }

  @Action({ commit: "setLayoutData" })
  async fetchData(context: any) {
    const token = new Platform(context.req.headers.host).getToken();
    try {
      const { data } = await axios.get(context.$config.astaEndpoint + "IVG/get", {
        headers: { Authorization: "Bearer " + token },
      });

      return data.data;
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }

      context.redirect("/error");
    }
  }

  @Action({ commit: "setSeoData" })
  async getSeoData(context: any) {
    const token = new Platform(context.req.headers.host).getToken();
    try {
      const { data } = await axios.get(context.$config.astaEndpoint + "seo", {
        headers: { Authorization: "Bearer " + token },
      });
      return data;
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      context.redirect("/error");
    }
  }

  @Action({ commit: "setAvailableGenre" })
  async getAvailableGenre(context: any) {
    const token = new Platform(context.req.headers.host).getToken();

    if (new Platform(context.req.headers.host).isAsta) {
      return ["IMMOBILI", "MOBILI", "AZIENDE", "VALORI/CREDITI", "ALTRO"];
    }

    try {
      const { data } = await axios.get(context.$config.astaEndpoint + "inserzioni/avaible-categories", {
        headers: { Authorization: "Bearer " + token },
      });
      return data?.data?.map((c: any) => c?.description);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        console.error(e);
      }
      throw e;
      // context.redirect("/error");
    }
  }

  @Action
  async getPopupAsta() {
    try {
      const { data } = await $axios.get(process.env.ASTA_ENDPOINT + "popupasta");
      return data;
    } catch (e) {
      throw e;
    }
  }

  @Action
  async getPopup() {
    try {
      const { data } = await $axios.get(process.env.ASTA_ENDPOINT + "popup");
      return data;
    } catch (e) {
      throw e;
    }
  }

  //GETTERS
  public get isAsta(): boolean {
    return this.main;
  }

  public get layout(): any {
    return this.layoutData;
  }

  public get headerData(): HeaderData {
    return {
      isAsta: this.main,
      logo: this.layoutData.logo,
      claim: this.layoutData.claim,
      prefix: this.layoutData.name_prefix,
      name: this.layoutData.name,
      mainMenu: this.layoutData.mainMenu.item,
    };
  }

  public get footerData(): FooterData {
    return {
      aggregates: this.layoutData.aggregates,
      piva: this.layoutData.vat_number,
      socials: this.layoutData.social,
      mainMenu: this.layoutData.mainMenu.item,
      footerLinks: this.layoutData.footerLink,
      footerMenu: this.layoutData?.footerMenu?.item,
      prefix: this.layoutData.name_prefix,
      name: this.layoutData.name,
      associato: this.layoutData.associato,
      gestoreVendite: this.layoutData.gestoreVendite,
      footerClaim: this.layoutData.footer_claim,
      informativa_contatti: this.layoutData.informativa_contatti,
      address:
        this.layoutData.address?.indirizzo +
        " | " +
        this.layoutData.address?.citta +
        " " +
        this.layoutData.address?.capZipCode +
        " - " +
        this.layoutData.address?.provincia,
      phone: this.layoutData.contacts?.telefono,
      email: this.layoutData.contacts?.email_principale,
      fax: this.layoutData.contacts?.fax,
    };
  }

  public get siteName(): string {
    return this.layoutData.name;
  }

  public get claim(): string {
    return this.layoutData.claim;
  }

  public get theme(): string {
    return startCase(this.layoutData.layout_type);
  }

  public get currentDomain(): string {
    return this.domain;
  }

  public get completeDomain(): string {
    return new Platform(this.domain).getDomain();
  }

  public get currentToken(): string {
    return this.token;
  }

  public get popup(): any[] {
    return this.layoutData.popup;
  }

  public get additionalModules(): Array<any> {
    return this.layoutData?.modules.filter((m: any) => m?.code !== "POPUPASTA");
  }

  public get additionalBanners(): Array<any> {
    let arr: Array<any> = [];
    if (this.layoutData?.banners) {
      for (const [key, value] of Object.entries(this.layoutData.banners)) {
        if (Array.isArray(value)) {
          value.forEach((v: any) => {
            arr.push(v);
          });
        }
      }
    }

    return arr;
  }

  public get genres(): string[] {
    return this.availableGenre;
  }

  public get privacy(): any {
    return this.layoutData.informativa_contatti;
  }

  public get ivgId(): number {
    return this.layoutData.id;
  }

  public get ivgPayload(): any {
    return this.layoutData.typesense_payload;
  }

  public get sedi(): Array<any> {
    return this.layoutData.sedi;
  }

  public get clientMap(): ClientMap {
    if (process.env.ENV === "local") {
      return {
        show: true,
        key: "" + process.env.GOOGLE_MAPS_API_KEY,
        hasStreetView: true,
      };
    }

    return {
      show: this.layoutData.map?.show ?? false,
      key: this.layoutData.map?.google_maps_key,
      hasStreetView: this.layoutData.map?.street_view ?? false,
    };
  }

  public get gaIds(): Array<string> {
    return this.layoutData.ga_ids;
  }

  public get aboutUsText(): any {
    return this.layoutData.testi_homepage;
  }

  public get cookieData(): CookieData {
    return {
      siteId: this.layoutData?.iubenda?.siteId ? Number(this.layoutData.iubenda.siteId) : null,
      cookiePolicyId: this.layoutData?.iubenda?.cookiePolicyId ? Number(this.layoutData.iubenda.cookiePolicyId) : null,
      apiKey: this.layoutData?.iubenda?.apiKey || null,
    };
  }

  public get isAssociato(): boolean {
    return this.layoutData?.associato;
  }

  public get canPubblicaSuAsta(): boolean {
    return this.layoutData?.pubblica_su_asta;
  }

  public get seo(): any {
    return this.seoData;
  }

  public get logoSrc(): string {
    return this.layoutData?.logo?.url;
  }

  public get categorieArricchite(): any {
    return this.layoutData?.categorie_arricchite?.slice(0, 6);
  }

  public get ivgIdentity(): IvgIdentity {
    return { claim: this.layoutData.claim, logo: this.layoutData.logo, name: this.layoutData.name, id: this.layoutData.id };
  }

  public get tidio(): string | null {
    return this.layoutData?.tidio || null;
  }

  public get lat(): number {
    return this.layoutData?.address?.lat || 42.232110896208044;
  }

  public get lng(): number {
    return this.layoutData?.address?.lng || 12.42206050014076;
  }

  public get facebookPixelId(): string {
    return this.layoutData?.facebook_pixel_id || "";
  }
}
