import { render, staticRenderFns } from "./TheCard.vue?vue&type=template&id=14d0447e&scoped=true"
import script from "./TheCard.vue?vue&type=script&lang=ts"
export * from "./TheCard.vue?vue&type=script&lang=ts"
import style0 from "./TheCard.vue?vue&type=style&index=0&id=14d0447e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d0447e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResultsCardTheSwiper: require('/usr/src/nuxt-app/components/results/card/TheSwiper.vue').default,ResultsCardTheTitle: require('/usr/src/nuxt-app/components/results/card/TheTitle.vue').default,ResultsCardTheCategoryTipology: require('/usr/src/nuxt-app/components/results/card/TheCategoryTipology.vue').default,ResultsCardThePrice: require('/usr/src/nuxt-app/components/results/card/ThePrice.vue').default,ResultsCardFooterData: require('/usr/src/nuxt-app/components/results/card/FooterData.vue').default})
