
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";

@Component
export default class SegnalazioniPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;

  public head() {
    return {
      title: "Segnalazioni | " + this.siteName,
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "trasparenza e legalità che contraddistinguono la mission dell’Associazione Nazionale Istituti Vendite Giudiziarie e può essere utilizzata da tutti coloro che vogliono evidenziare e segnalare alla Associazione casi non conformi alle specifiche procedurali delle vendite.",
        },
      ],
    };
  }
}
