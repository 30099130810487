
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";

@Component
export default class TheStatus extends Vue {
  @Prop() value!: any;

  public options: Array<any> = [
    { text: "In vendita", value: "In vendita", disabled: false },
    { text: "In aggiornamento", value: "In aggiornamento", disabled: false },
    { text: "Non aggiudicato", value: "Non aggiudicato", disabled: false },
    { text: "Venduto", value: "Venduto", disabled: false },
    { text: "Sospeso", value: "Sospeso", disabled: false },
    { text: "Estinto", value: "Estinto", disabled: false },
    { text: "Da definire", value: "Da definire", disabled: false },
    { text: "Concluso", value: "Concluso", disabled: false },
    { text: "Parzialmente venduto", value: "Parzialmente venduto", disabled: false },
    { text: "In attesa di offerte migliorative", value: "In attesa di offerte migliorative", disabled: false },
  ];

  @Watch("value.status", { immediate: true })
  setDisabled() {
    const status = [...this.value.status];
    if (status.length === 1) {
      this.options.forEach((option: any) => {
        if (status[0] === option.value) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
    } else {
      this.options.forEach((option: any) => {
        option.disabled = false;
      });
    }
  }

  emitUpdate(e: string[]) {
    this.$emit("set-value", e);
  }
}
