export default class FormSegnalazioni {
  IVG_competenza: number | null = null;
  tipo_persona: string = "Privato";
  ragione_sociale: string = "";
  nome: string = "";
  cognome: string = "";
  email: string = "";
  telefono: string = "";

  segnalazione: string = "";
  numero_procedura: string = "";
  anno_procedura: number | string = "";
  data_acquisto?: string = "";
  privacy: boolean = false;
  recaptcha: string = "";
}
