
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";

@Component
export default class TheGracePeriod extends Vue {
  @Prop() value!: any;

  emitUpdate(e: any) {
    const today = this.$dayjs().startOf("day").unix();

    if (!e) {
      this.$emit("set-array-of-filter-value", [
        { filter: "filter/setStatusFilter", value: ["In vendita"] },
        { filter: "filter/setGracePeriod", value: [] },
      ]);
    } else {
      this.$emit("set-array-of-filter-value", [
        { filter: "filter/setStatusFilter", value: ["In attesa di offerte migliorative"] },
        { filter: "filter/setGracePeriod", value: [">", today] },
      ]);
    }
  }
}
