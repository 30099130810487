
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import { IvgMapDataResponse, IvgMapData } from "@/types/responses/chisiamo/IvgMapResponse";
import { ClientMap } from "~/types/responses/inserzione/ResponseInserzioneInterface";

@Component
export default class TheMap extends Vue {
  @Prop() clientMap!: ClientMap;

  public componentLoader: any | null = null;

  async mounted() {
    await this.fetchIvgCoordinates();
  }

  // (fix Expected mapDiv of type HTMLElement but was passed undefined)
  beforeDestroy() {
    this.componentLoader = null;
  }

  async fetchIvgCoordinates() {
    try {
      const { data } = await this.$axios.$get<IvgMapDataResponse>(this.$config.astaEndpoint + "IVG/get-mapdata");

      const points = data.map((item) => {
        return {
          position: {
            lat: item.lat,
            lng: item.lng,
          },
          title: item.name,
          id: item.id,
          associato: item.associato,
        };
      });

      await this.initMap(points);
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      throw e;
    }
  }

  public async initMap(points: any[] = []) {
    this.componentLoader = await this.mapComponentLoader();
    const google = await this.componentLoader.load();
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");

    const map = new Map(this.$refs.googleMap, {
      center: {
        lat: 42.152636,
        lng: 12.345637,
      },
      zoom: 6,
      mapId: process.env.GOOGLE_MAPS_ID,
    });

    const infoWindow = new InfoWindow();
    for (let i = 0; i < points.length; i++) {
      const pin = new PinElement({
        background: points[i].associato ? "#0052cc" : "#017a05",
        borderColor: points[i].associato ? "#0061f2" : "#015c04",
        glyphColor: "white",
      });

      const marker = new AdvancedMarkerElement({
        map,
        position: points[i].position,
        title: points[i].title,
        content: pin.element,
      });

      google.maps.event.addListener(marker, "click", () => {
        infoWindow.close();
        infoWindow.setContent(this.markerHTML(points[i]));
        infoWindow.open(map, marker);
        if (map) {
          map.panTo(marker.position);
        }
      });
    }
  }

  public markerHTML(point: any) {
    return `<p class="mb-0 py-4 px-5 text-white h4 text-center">${point.title}<br/><a target="_blank" class="btn btn-default rounded-pill py-1 mt-2" href="/ivg/?ivg=${point.id}">Vai alla pagina</a></p>`;
  }

  public mapComponentLoader() {
    if (this.componentLoader) {
      return this.componentLoader;
    }
    return new Loader({
      apiKey: this.clientMap.key,
      version: "weekly",
      language: "it",
      libraries: ["marker", "geometry", "drawing"],
    });
  }
}
