import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Filters, { FilterDictionary, SearchParameters, coutableFilters, GenreCollection, FilterMutations } from "~/types/Filters";
// import merge from "lodash.merge";
import { isEmpty } from "@/helpers/isEmpty";
import { store } from "./index";
import { $axios } from "~/utils/api";
import qs from "qs";

type ivgPayload = {
  name: string;
  value: string[] | number[];
};

@Module({
  name: "filter",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class FilterStore extends VuexModule {
  public filter: any = new Filters();

  public filterOptions: any = {
    category: [],
    subcategory: [],
    ivg_short_name: [],
    province: [],
    city: [],
  };
  public priceStats: any = { min: 0, max: 1 };
  public searchSaved: boolean = false;
  public circle: any = {};
  public polygon: any = {};
  public mapBounds: any = {};

  @Mutation
  async resetQueryParams(payload: any) {
    const genre = this.filter.filter.genre;
    this.filter = new Filters(payload.ivgId);
    if (payload?.route?.currentRoute?.name === new GenreCollection().getAlias(genre[0])) {
      this.filter.filter.genre = genre;
    }

    if (!isEmpty(payload?.route?.currentRoute?.query)) {
      await payload.route.push(payload.route.currentRoute.path);
    }
  }

  @Mutation
  setCircle(value: any) {
    this.circle = value;
  }

  @Mutation
  setPolygon(value: any) {
    this.polygon = value;
  }

  @Mutation
  setMapBounds(value: any) {
    this.mapBounds = value;
  }

  @Mutation
  resetPolygon() {
    this.polygon = {};
  }

  @Mutation
  resetCircle() {
    this.circle = {};
  }

  @Mutation
  resetMapBounds() {
    this.mapBounds = {};
  }

  @Mutation
  initFilterVisibileSu(ivgId: number) {
    this.filter = new Filters(ivgId);
  }

  @Mutation
  commitIvgPayload(ivgPayload: ivgPayload[] | null) {
    if (!ivgPayload) return;
    if (!ivgPayload.length) return;

    ivgPayload.forEach((item) => {
      this.filter.filter[item.name] = item.value;
    });

    const sort = ivgPayload.find((v: any) => v.name === "sort_by");
    if (sort) {
      const arr = String(sort.value[0]).split(":");
      this.filter.sort = { key: arr[0], value: arr[1] };
    }
  }

  @Action
  setIvgPayload() {
    const ivgPayload: ivgPayload[] | null = this.context.rootGetters["tenant/ivgPayload"];

    if (!ivgPayload) return;
    if (!ivgPayload.length) return;

    const mutations = new FilterMutations().data;
    ivgPayload.forEach((item) => {
      const filter = mutations.find((mutation: any) => mutation.name === item.name);
      if (filter?.mutation) {
        this.context.commit(filter?.mutation, item.value);
      }
    });

    const sort = ivgPayload.find((v: any) => v.name === "sort_by");
    if (sort) {
      const arr = String(sort.value[0]).split(":");
      this.context.commit("setSort", { key: arr[0], value: arr[1] });
    }
  }

  @Action
  setFilterVisibileSu() {
    this.context.commit("initFilterVisibileSu", this.context.rootGetters["tenant/ivgId"]);
  }

  @Mutation
  resetOptions() {
    this.filterOptions = { category: [], subcategory: [] };
  }

  @Mutation
  updateOptionsMutation(value: any) {
    this.filterOptions = { ...this.filterOptions, ...value };
  }

  @Mutation
  setRpp(value: number) {
    this.filter.rpp = value;
  }

  @Mutation
  clearSearchSaved() {
    this.searchSaved = false;
  }

  @Mutation
  setSearchSaved() {
    this.searchSaved = true;
  }

  @Mutation
  setPage(value: number) {
    this.filter.page = value;
  }

  @Mutation
  setQueryText(value: any) {
    this.filter.query = value;
  }

  @Mutation
  resetQuery() {
    this.filter.query = "";
  }

  @Mutation
  setHasGaraOnlineFilter(value: any) {
    this.filter.filter.hasGaraOnline = value;
  }

  @Mutation
  resetHasGaraOnlineFilter(value: any) {
    this.filter.filter.hasGaraOnline = [];
  }

  @Mutation
  setStatusFilter(value: any) {
    this.filter.filter.status = value;
  }

  @Mutation
  setVisibileSuFilter(value: number[]) {
    this.filter.filter.visibile_su = value;
  }

  @Mutation
  resetStatusFilter(value: any) {
    this.filter.filter.status = ["In vendita"];
  }

  @Mutation
  setIvgFilter(value: any) {
    this.filter.filter.ivg_short_name = value;
  }

  @Mutation
  setIvgNumberFilter(value: any) {
    this.filter.filter.ivg_number = value;
  }

  @Mutation
  setControparteFilter(value: any) {
    this.filter.filter.controparte = value;
  }
  @Mutation
  setTipoProceduraFilter(value: any) {
    this.filter.filter.tipo_procedura = value;
  }

  @Mutation
  setProvinceFilter(value: any) {
    this.filter.filter.province = value;
  }

  @Mutation
  setCityFilter(value: any) {
    this.filter.filter.city = value;
  }

  @Mutation
  setSubcategoryFilter(value: any) {
    this.filter.filter.subcategory = value;
  }

  @Mutation
  setCategoryFilter(value: any) {
    this.filter.filter.category = value;
  }

  @Mutation
  setGenreFilter(value: any) {
    this.filter.filter.genre = value;
  }

  @Mutation
  resetCategoryFilter() {
    this.filter.filter.category = [];
  }

  @Mutation
  resetIvg() {
    this.filter.filter.ivg_short_name = [];
  }

  @Mutation
  resetIvgNumber() {
    this.filter.filter.ivg_number = [];
  }

  @Mutation
  resetControparte() {
    this.filter.filter.controparte = [];
  }

  @Mutation
  resetTipoProcedura() {
    this.filter.filter.tipo_procedura = [];
  }

  @Mutation
  resetProvince() {
    this.filter.filter.province = [];
  }

  @Mutation
  resetCity() {
    this.filter.filter.city = [];
  }

  @Mutation
  resetTags(value: any) {
    this.filter.filter.tags = [];
  }

  @Mutation
  resetSubcategoryFilter() {
    this.filter.filter.subcategory = [];
  }

  @Mutation
  resetGenreFilter(value: any) {
    this.filter.filter.genre = [];
  }

  @Mutation
  setSort(value: any) {
    this.filter.sort = {};
    if (!isEmpty(value)) {
      this.filter.sort = {
        key: value.key,
        value: value.value,
      };
    }
  }

  @Mutation
  setPriceStats(value: any) {
    this.priceStats.min = value.min;
    this.priceStats.max = value.max;
  }

  @Mutation
  setPrice(value: any) {
    if (value?.length) {
      this.filter.filter.price = [value[0], value[1]];
    }
  }

  @Mutation
  resetPrice() {
    this.filter.filter.price = [];
  }

  @Mutation
  setPosition(value: any) {
    this.filter.filter.position = [value];
  }

  @Mutation
  resetPosition(value: any) {
    this.filter.filter.position = "";
    this.circle = {};
    this.polygon = {};
    this.mapBounds = {};
  }

  @Mutation
  setDataVenditaSearch(value: Array<number>) {
    if (Number(value[0]) || Number(value[1])) {
      this.filter.filter.data_vendita_search = value;
    } else {
      this.filter.filter.data_vendita_search = [];
    }
  }

  @Mutation
  setLatestSearch(value: Array<number>) {
    if (Number(value[0])) {
      this.filter.filter.latest_search = value;
    } else {
      this.filter.filter.latest_search = [];
    }
  }
  @Mutation
  resetLatestSearch(value: Array<number>) {
    if (Array.isArray(value) && Number(value[0])) {
      this.filter.filter.latest_search = value;
    } else {
      this.filter.filter.latest_search = [];
    }
  }

  //  GRACE PERIOD
  @Mutation
  setGracePeriod(value: number[]) {
    if (value?.length) {
      this.filter.filter.grace_period_scadenza = [value[0], value[1]];
    } else {
      this.filter.filter.grace_period_scadenza = [];
    }
  }
  @Mutation
  resetGracePeriod(value: Array<number>) {
    this.filter.filter.grace_period_scadenza = [];
  }

  @Mutation
  resetDataVenditaSearch(value: Array<number>) {
    this.filter.filter.data_vendita_search = [];
  }

  @Mutation
  resetFilterMutation(ivgId: number) {
    this.filter = new Filters(ivgId);
  }

  @Mutation
  resetFilterMutationWithoutQuery(ivgId: number) {
    const query = this.filter.query;
    const filter = new Filters(ivgId);
    filter.query = query;
    this.filter = filter;
  }

  @Mutation
  setFilterMutation(data: any) {
    this.filter = {
      filter: { ...this.filter.filter, ...data.filter },
      query: data.query || this.filter.query || "",
      page: data.page || this.filter.page || 1,
      rpp: data.rpp || this.filter.rpp || 20,
      sort: data.sort || this.filter.sort,
    };
  }

  @Mutation
  cloneFilterFromFilterMap(data: any) {
    this.filter = {
      filter: { ...data },
      query: this.filter.query || "",
      page: this.filter.page || 1,
      rpp: this.filter.rpp || 20,
      sort: this.filter.sort,
    };
  }

  @Mutation
  setNewQueryParams(route: any) {
    let obj: any = { ...this.filter };
    let pathTo = "/ricerca";
    if (this.filter.filter.genre.length) {
      pathTo += "/" + new GenreCollection().getAlias(this.filter.filter.genre[0]);
    }
    route.push({ path: pathTo, query: obj });
  }

  @Mutation
  emptyFilter(filter: string) {
    this.filter.filter[filter] = [];
  }
  @Action({ commit: "emptyFilter" })
  doEmptyFilter(filter: string) {
    return filter;
  }

  @Action({ commit: "setFilterMutation" })
  async makeFilterMutation(context: any = null) {
    //@ts-ignore
    return context ? context.route.query : store._vm.$nuxt.$store.$router.currentRoute.query;
  }

  @Action({ commit: "setFilterMutation" })
  async makeFilterMutationUsingQuery(query: any) {
    return query;
  }

  @Action({ commit: "setPriceStats" })
  updatePriceStats(value: any) {
    if (value.total_values) {
      if (value.min < 0) {
        return {
          min: 0,
          max: value.max,
        };
      }
      return value;
    }
    return { min: 0, max: 0 };
  }

  @Action
  async resetFilter() {
    this.context.commit("resetFilterMutation", this.context.rootGetters["tenant/ivgId"]);
    // add initTenantFilters
    // @ts-ignore
    this.context.commit("resetQueryParams", store._vm.$nuxt.$router, this.context.rootGetters["tenant/ivgId"]);
  }

  @Action
  async setFilter(filters: any) {
    this.context.commit("setFilterMutation", filters);
  }

  @Action
  async setQueryParams() {
    // @ts-ignore
    await this.context.commit("setNewQueryParams", store._vm.$nuxt.$router);
  }

  @Action({ commit: "updateOptionsMutation" })
  updateOptions(value: any) {
    return value;
  }

  @Action
  updateSavedSearch(value: any) {
    value ? this.context.commit("setSearchSaved") : this.context.commit("clearSearchSaved");
  }

  @Action
  async saveSearch() {
    const searchParamenterAtPageOne = { ...this.context.getters["searchParameters"] };
    searchParamenterAtPageOne.page = 1;
    const filters = this.context.getters["getFilterAll"];
    const query_by =
      `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${this.context.rootGetters["typesense/key"]}&` +
      qs.stringify(searchParamenterAtPageOne);

    const url = new URL(window.location.href);

    let searchLink = url.origin;
    if (filters.filter.genre.length) {
      searchLink += `/ricerca/${new GenreCollection().getAlias(filters.filter.genre[0])}`;
    } else {
      searchLink += `/ricerca`;
    }

    searchLink += `?${qs.stringify(filters)}`;

    await $axios.$post(process.env.ASTA_ENDPOINT + "myAsta/ricerche", {
      payload: {
        ...filters.filter,
        query: this.context.getters["getQuery"],
        searchLink,
        query_by,
      },
    });

    this.context.commit("setSearchSaved");
  }
  /**
   * GETTER
   */

  public get filterCount() {
    let statusCount = 0;
    if (
      (this.filter.filter["status"].length === 1 && this.filter.filter["status"][0] === "In vendita") ||
      this.filter.filter["status"].length > 6
    ) {
      statusCount = -1;
    }
    return (
      coutableFilters.filter((item: any) => {
        return this.filter.filter[item]?.length > 0;
      })?.length +
      (this.filter.query?.length > 0 ? 1 : 0) +
      statusCount
    );
  }

  public get getSavedSearchStatus() {
    return this.searchSaved;
  }

  public get getQuery() {
    return this.filter.query;
  }
  public get perPage() {
    return this.filter.rpp;
  }

  public get getFilter() {
    return this.filter.filter;
  }

  public get getCategories() {
    return this.filter.filter.category;
  }

  public get getSubcategories() {
    return this.filter.filter.subcategory;
  }

  public get getPosition() {
    return this.filter.filter.position;
  }

  public get getStatus() {
    return this.filter.filter.status;
  }

  public get getFilterAll() {
    return this.filter;
  }

  public get filtersOptions() {
    return this.filterOptions;
  }

  public get getControparte() {
    return this.filterOptions?.controparte?.filter((item: any) => item.value !== "Altro" && item.value !== "");
  }

  public get getTipoProcedura() {
    return this.filterOptions?.tipo_procedura;
  }

  public get genre() {
    return this.filter.filter.genre.length ? this.filter.filter.genre[0].toLowerCase() : "default";
  }

  public get isGenere() {
    return this.filter.filter.genre.length > 0;
  }

  public get countDaysInDate() {
    return this.filter.filter.data_vendita_search;
  }

  public get latestDays() {
    return this.filter.filter.latest_search;
  }

  public get getPriceStats() {
    return this.priceStats;
  }

  //TYPESENSE GETTERS
  public get searchParameters() {
    let query = this.filter.query;
    let filter_by = this.searchParamsFilters;
    let page = this.filter.page;
    let per_page = this.filter.rpp;
    if (!isEmpty(this.filter.sort)) {
      let sort_by = `${this.filter.sort.key}:${this.filter.sort.value}`;
      return new SearchParameters(query, filter_by, page, per_page, sort_by);
    }
    return new SearchParameters(query, filter_by, page, per_page);
  }

  public get searchParamsFilters() {
    const filters: any = {};
    const dictionary = new FilterDictionary().dictionary;
    Object.keys(this.filter.filter).forEach((item) => {
      if (this.filter.filter[item].length > 0) {
        if (dictionary.exactTerms.includes(item)) {
          filters[item] = `${item}:=${this.filter.filter[item].join(",")}`;
        }
        if (dictionary.terms.includes(item)) {
          filters[item] = `${item}: [${this.filter.filter[item].join(",")}]`;
        }
        if (dictionary.range.includes(item)) {
          if (isNaN(Number(this.filter.filter[item][0]))) {
            filters[item] = `${item}: ${this.filter.filter[item][0] + this.filter.filter[item][1]}`;
          } else {
            filters[item] = `${item}: [${this.filter.filter[item][0]}..${this.filter.filter[item][1]}]`;
          }
        }
        if (dictionary.geo.includes(item)) {
          filters[item] = `${item}: ${this.filter.filter[item]}`;
        }
      }
    });
    return Object.values(filters).join(" && ");
  }

  public get searchParamsFiltersNoCategoryAndTypology() {
    const filters: any = {};
    const dictionary = new FilterDictionary().dictionary;
    dictionary.terms = ["genre", "status", "inserzioneEspVendita", "hasGaraOnline", "visibile_su"];
    Object.keys(this.filter.filter).forEach((item) => {
      if (this.filter.filter[item].length > 0) {
        if (dictionary.exactTerms.includes(item)) {
          filters[item] = `${item}:=${this.filter.filter[item].join(",")}`;
        }
        if (dictionary.terms.includes(item)) {
          filters[item] = `${item}: [${this.filter.filter[item].join(",")}]`;
        }
        if (dictionary.range.includes(item)) {
          if (isNaN(Number(this.filter.filter[item][0]))) {
            filters[item] = `${item}: ${this.filter.filter[item][0] + this.filter.filter[item][1]}`;
          } else {
            filters[item] = `${item}: [${this.filter.filter[item][0]}..${this.filter.filter[item][1]}]`;
          }
        }
      }
    });
    return Object.values(filters).join(" && ");
  }

  public get getCircle(): any {
    return this.circle;
  }

  public get getPolygon(): any {
    return this.polygon;
  }

  public get getMapBounds(): any {
    return this.mapBounds;
  }
}
