var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100",on:{"mouseleave":function($event){return _vm.toggleHover(false)},"mouseover":function($event){return _vm.toggleHover(true)}}},[(_vm.hover && _vm.isMap)?_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"btn-wrapper"},[_c('b-button',{staticClass:"px-3",attrs:{"to":'/' + _vm.item.permalink,"variant":"dark","pill":""}},[_c('b-icon',{attrs:{"icon":"link","font-scale":"1.3"}}),_vm._v("\n        Scopri l'immobile\n      ")],1)],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card overflow-hidden border-1 p-0 asta-card justify-content-between position-relative",class:{
      hovered: _vm.hover,
      'not-on-sale': _vm.item.status !== 'In vendita',
      'grace-period': _vm.item.status === 'In attesa di offerte migliorative' && _vm.$dayjs().unix() < _vm.item.grace_period_scadenza,
      'missing-position': _vm.isMap && _vm.item?.position?.length === undefined,
    },on:{"click":function($event){return _vm.cardClicked(_vm.item.id)}}},[_c('div',{staticClass:"row no-gutters",class:!_vm.iscard ? 'h-100' : ''},[_c('div',{staticClass:"bordered-xxl img-ctn col-12",class:{ 'col-sm-5 col-md-5 col-lg-5 col-xl-5': !_vm.iscard }},[_c('ResultsCardTheSwiper',{attrs:{"clickdisabled":_vm.clickdisabled,"images":_vm.item.gallery,"iscard":_vm.iscard,"card-with-swiper":_vm.cardWithSwiper,"item":_vm.item,"show-calendar":_vm.showCalendar},on:{"card-clicked":function($event){return _vm.$emit('card-clicked')},"is-loading":_vm.isLoading,"toggle-share-modal":function($event){return _vm.$emit('toggle-share-modal', $event)},"toggle-favourite":function($event){return _vm.$emit('toggle-favourite', $event)},"toggle-login-modal":function($event){return _vm.$emit('toggle-login-modal', $event)}}})],1),_vm._v(" "),_c('div',{staticClass:"col-12",class:[
          { 'col-sm-7 col-md-7 col-lg-7 col-xl-7 d-flex flex-column pt-3 justify-content-between list-footer-border ': !_vm.iscard },
          _vm.item.status === 'In attesa di offerte migliorative' && _vm.$dayjs().unix() < _vm.item.grace_period_scadenza ? '' : 'bg-white',
        ]},[_c('div',[_c('ResultsCardTheTitle',{attrs:{"item":_vm.item,"iscard":_vm.iscard,"clickdisabled":_vm.clickdisabled,"is-asta":_vm.isAsta,"show-icon":_vm.showIcon,"has-grace-period":_vm.item.status === 'In attesa di offerte migliorative' && _vm.$dayjs().unix() < _vm.item.grace_period_scadenza}}),_vm._v(" "),_c('ResultsCardTheCategoryTipology',{attrs:{"item":_vm.item,"iscard":_vm.iscard}}),_vm._v(" "),_c('ResultsCardThePrice',{attrs:{"item":_vm.item,"isCard":_vm.iscard}})],1),_vm._v(" "),(!_vm.iscard)?_c('ResultsCardFooterData',{staticClass:"list-footer-border mt-2",class:{ 'no-gutters': !_vm.iscard },attrs:{"item":_vm.item,"iscard":_vm.iscard}}):_vm._e()],1)]),_vm._v(" "),(_vm.iscard)?_c('div',{staticClass:"card-footer bg-gray-card border-0 mt-2"},[_c('ResultsCardFooterData',{attrs:{"item":_vm.item,"iscard":_vm.iscard}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }