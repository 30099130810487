import { render, staticRenderFns } from "./TheAsta.vue?vue&type=template&id=0bb3d712&scoped=true"
import script from "./TheAsta.vue?vue&type=script&lang=ts"
export * from "./TheAsta.vue?vue&type=script&lang=ts"
import style0 from "./TheAsta.vue?vue&type=style&index=0&id=0bb3d712&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb3d712",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowAstaMinOffer: require('/usr/src/nuxt-app/components/show/asta/MinOffer.vue').default,ShowAstaBasePrice: require('/usr/src/nuxt-app/components/show/asta/BasePrice.vue').default,ShowAstaLabelValue: require('/usr/src/nuxt-app/components/show/asta/LabelValue.vue').default,ShowAstaTheLabels: require('/usr/src/nuxt-app/components/show/asta/TheLabels.vue').default,ShowFormsTheBooking: require('/usr/src/nuxt-app/components/show/forms/TheBooking.vue').default,ShowAstaCardActions: require('/usr/src/nuxt-app/components/show/asta/CardActions.vue').default})
